var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "my-4 show-on-print-only avo-card" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("strong", [_vm._v("Estimate for:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.patient
                        ? _vm.patient.full_name
                        : "No patient selected"
                    )
                ),
              ]),
              _c("b-col", [
                _c("strong", [_vm._v("Estimate date:")]),
                _vm._v(" " + _vm._s(_vm.renderCurrentDate())),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "do-not-show-on-print",
              attrs: { sm: "6", md: "4" },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-size": "lg", label: "Practice" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("practice-multiselect", {
                            attrs: { multiple: false, "close-on-select": true },
                            model: {
                              value: _vm.practice,
                              callback: function ($$v) {
                                _vm.practice = $$v
                              },
                              expression: "practice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-size": "lg", label: "Patient" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("patient-selection", {
                            model: {
                              value: _vm.patient,
                              callback: function ($$v) {
                                _vm.patient = $$v
                              },
                              expression: "patient",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-col", [
            _c(
              "div",
              { staticClass: "card-table mb-3 px-3" },
              [
                _c(
                  "b-row",
                  { staticClass: "mb-3 header-row" },
                  [
                    _c("b-col", { staticClass: "pt-2 pl-3" }, [
                      _c("span", { staticClass: "small-header-text" }, [
                        _vm._v("Patient insurance details:"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("insurance-list", {
                  attrs: {
                    "patient-id": _vm.patient ? _vm.patient.patient_id : null,
                    "patient-name": _vm.patient ? _vm.patient.full_name : null,
                    noDataMessage: "Select a patient to see insurance details.",
                    "table-style": "border:none;font-size:12px;",
                    "table-class": "no-shadow",
                    borderless: "",
                  },
                  on: {
                    insurancesUpdated: function ($event) {
                      return _vm.setInsurances($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-table mb-3 px-3" },
        [
          _c(
            "b-row",
            { staticClass: "mb-3 header-row" },
            [
              _c("b-col", { staticClass: "pt-2 pl-3" }, [
                _c("span", { staticClass: "small-header-text" }, [
                  _vm._v("Services:"),
                ]),
              ]),
            ],
            1
          ),
          _c("cpt-codes-list", {
            staticClass: "mb-3",
            attrs: {
              "practice-id": _vm.practice ? _vm.practice.practice_id : null,
              estimate: true,
              payerId: _vm.payer_id,
              patientInsuranceBenefits: _vm.primaryInsurance,
              outlined: false,
              borderless: true,
              "table-class": "",
              "table-style": "border:none 0px #E2E4EB;font-size:14x;",
              noDataMessage: "Add a service to start an estimate.",
            },
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "my-4 show-on-print-only avo-card" },
        [
          _c("b-row", { staticClass: "ml-1 mb-4" }, [
            _vm._v(
              " This is an estimate based on the scheduled procedures. Actual charges may differ based on procedures performed. "
            ),
          ]),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("span", [
                  _c("strong", [_vm._v("Signature:")]),
                  _vm._v(" __________________________________"),
                ]),
              ]),
              _c("b-col", [
                _c("span", [
                  _c("strong", [_vm._v("Date:")]),
                  _vm._v(" ______/______/________"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "my-2" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "avo-primary-btn mr-2",
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.printEstimate()
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "printer" },
                  }),
                  _vm._v("Print Estimate "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }